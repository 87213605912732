<template>
<div id="maurer_app">
    <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>
</div>
</template>

<style>
@import './assets/css/introjs.min.css';
@import './assets/css/pages/login/classic/login-6.css';
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700");
@import './assets/plugins/global/plugins.bundle.css';
@import './assets/plugins/custom/prismjs/prismjs.bundle.css';
@import './assets/css/style.bundle.min.css';
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
  transition-timing-function: ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

   <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-NJXXGMDCBM"></script>
      <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-NJXXGMDCBM');
      </script>

<!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/61a4dbd79099530957f724ae/1fllvlh1t';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
</script>
<!--End of Tawk.to Script-->
