<template>
<div :id="id" class="g-recaptcha g-recaptcha--left g-recaptcha--mobile-hidden" :data-sitekey="sitekey"></div>
</template>

<script>
export default {
    name: 'recaptcha-invisible',
    props: {
        sitekey: {
            type: String,
            required: true
        },

        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            gAssignedId: null,
            captchaReady: false,
            checkInterval: null
        }
    },

    methods: {
        execute() {
            window.grecaptcha.execute(this.gAssignedId)
        },
        reset() {
            window.grecaptcha.reset(this.gAssignedId)
        },
        render() {
            this.gAssignedId = window.grecaptcha.render(this.id, {
                sitekey: this.sitekey,
                size: 'invisible',
                'callback': (recaptchaToken) => {
                    this.$emit('callback', recaptchaToken)
                    this.reset()
                },
                'expired-callback': () => {
                    this.reset()
                }
            })
        },
        init() {
            this.checkInterval = setInterval(() => {
                if (window.grecaptcha && Object.prototype.hasOwnProperty.call(window.grecaptcha, 'render')) {
                    this.captchaReady = true
                }
            }, 500)
        }
    },
    watch: {
        captchaReady: function (data) {
            if (data) {
                clearInterval(this.checkInterval)
                this.render()
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.grecaptcha-badge {
	z-index: 1000;
}

.g-recaptcha--left .grecaptcha-badge {
	width: 70px !important;
	overflow: hidden;
	transition: all 0.2s ease !important;
	left: 0px;
}

.g-recaptcha--left .grecaptcha-badge:hover {
	width: 256px !important;
}

@media (max-width: 992px) {
	.g-recaptcha--mobile-hidden .grecaptcha-badge {
		display: none;
	}
}
</style>
