import Vue from 'vue'
import VueRouter from 'vue-router'
import UUID from "vue-uuid";
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import VueIntro from 'vue-introjs'
Vue.use(VueIntro);
import 'intro.js/introjs.css';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(UUID);
Vue.use(VueRouter);
import ReCaptchaInvisible from './components/ReCaptchaInvisible.vue';
Vue.component('recaptcha-invisible', ReCaptchaInvisible);
import { Datetime } from 'vue-datetime';
 
Vue.component('datetime', Datetime);

import VMdDateRangePicker from "v-md-date-range-picker";

Vue.use(VMdDateRangePicker);

// Rimuove i tips (es. variabile non utilizzata)
Vue.config.productionTip = false;
// Rimuove i warning
Vue.config.silent = true;

new Vue({
  render: h => h(App),
}).$mount('#app')

const routes = [
  { 
    name: 'login',
    path: '/login/:ref?',
    meta: {
      requiresAuth: false
    },
    component: () => import('./pages/Authentication/Login.vue')
  },

  { 
    name: 'dashboard',
    path: '/',
    meta: {
      requiresAuth: true
    },
    redirect: '/home',
    component: () => import('./pages/Dashboard/Dashboard.vue'),
    children: [
      
      { 
        path: '/home',
        name: 'Dashboard',
        component: () => import('./pages/Dashboard/Home.vue')
      },
      

      { 
        path: '/settings',
        name: 'Settings',
        component: () => import('./pages/Dashboard/Settings.vue')
      },

      { 
        path: '/referal',
        name: 'Refrerral',
        component: () => import('./pages/Dashboard/Referal.vue')
      },

      { 
        path: '/pnl',
        name: 'PNL',
        component: () => import('./pages/Dashboard/PNL.vue')
      },
      
      { 
        path: '/plans',
        name: 'Plans',
        component: () => import('./pages/Dashboard/Plans.vue')
      },
      
    ]
  }
]

const router = new VueRouter({
  routes: routes
})
window.router = router; // lo rendo accessibile esternamente tramite window.router


router.beforeEach((to, from, next) => {
  console.log(to == undefined);
  if (to.matched.some(record => record.meta.requiresAuth)) { // se la pagina richiede autenticazione
    if (localStorage.PUMPMOB_TOKEN == undefined) {
      next({ name: 'login' }) // redirect al login
    } else {
      next() // prosegui
    }
  } else if (!to.matched.some(record => record.meta.requiresAuth)) { // caso speciale, pagina login, non ci puoi andare se sei già loggato
    if (localStorage.PUMPMOB_TOKEN != undefined) {
      next({ name: 'dashboard' }) // redirect alla dash
    } else {
      next() // prosegui
    }
  } else { // pagine in cui non è specificato
    next() // vai dove vuoi
  }
})

router.beforeResolve((to, from, next) => {
  // inizio caricamento
  next()
})

router.afterEach(() => {
  // fine caricamento
})


window.addEventListener("load", function() { // Inizializzo vue solo dopo che il caricamento della pagina è completo (tutti gli altri assets js caricati)
  new Vue({
    router: router,
    el: '#maurer_app',
    render: h => h(App)
  })
});

